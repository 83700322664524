import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import "./App.css";
import AddClient from "./components/AddClient";
import AddUser from "./components/AddUser";
import AddTask from "./components/AddTask";
import TaskDetail from "./components/TaskDetail";

function App() {
  return (
    <Router>
      <div className="container">
        <div className="columns">
          <div className="column is-half is-offset-one-quarter">
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/addclient" element={<AddClient />} />
              <Route exact path="/adduser" element={<AddUser />} />
              <Route exact path="/addtask" element={<AddTask />} />
              <Route exact path="/:id" element={<TaskDetail />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
