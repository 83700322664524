import Navbar from "./NavBar";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useState, useEffect } from 'react'

export default function AddUser() {
  const navigate = useNavigate();
  const [client, setClient] = useState([]);

  const getClients = async () => {
    try {
      const res = await axios.get("/clients");
      setClient(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClients();
  }, []);
  //console.log("users:", client);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });
  const onSubmit = (data) => {
    //console.log(data);
    saveUsers(data);
    reset({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
    alert("Cliente Creado con Exito")
    getClients();
    //navigate("/");
  };

  console.log(errors);

  const saveUsers = async (e) => {
    //e.preventDefault();
    //console.log("client", e);
    await axios.post("/clients", {
      client_name: e.firstName,
      client_lastName: e.lastName,
      client_email: e.email,
      client_phone: e.phone,
    });
  };

  return (
    <div>
      <Navbar />
      <h3>Alta Cliente</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            First Name
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_name"
            mane="user_name"
            type="text"
            placeholder="First name"
            // value={user.user_name}
            {...register("firstName", { required: true, maxLength: 50 })}
            // onChange={(e) => {
            //   handleChange(e);
            // }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="user_lastName" className="form-label">
            Last Name
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_lastName"
            name="user_lastName"
            type="text"
            placeholder="Last name"
            // value={user.user_lastName}
            {...register("lastName", { required: true, maxLength: 50 })}
            // onChange={(e) => {
            //     handleChange(e);
            //   }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="user_email" className="form-label">
            Email
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_email"
            name="user_email"
            type="email"
            placeholder="Email"
            // value={user.user_email}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            // onChange={(e) => {
            //     handleChange(e);
            //   }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="user_phone" className="form-label">
            Phone Number
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_phone"
            name="user_phone"
            type="tel"
            placeholder="Phone number"
            // value={user.user_phone}
            {...register("phone", { required: true, maxLength: 12 })}
            // onChange={(e) => {
            //     handleChange(e);
            //   }}
          />
        </div>

        <input type="submit" className="btn btn-primary" />
      </form>
      <div style={{ padding: "20px 0 0 0" }}>
        <table className="table">
          <thead className="table-secondary">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Telefono</th>
            </tr>
          </thead>
          <tbody>
            {client.map((client) => (
              <tr key={client.id}>
                <td>
                  <Link to={`/${client.id}`} style={{ textDecoration: "none" }}>
                    {client.client_name}
                  </Link>
                </td>
                <td> {client.client_lastName} </td>
                <td> {client.client_email} </td>
                <td> {client.client_phone} </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
