import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className="d-flex bg-info flex-row mb-3 justify-content-between">
      <div className="p-2">
        <ul className="nav">
          <li className="nav-item">
            <div
              className="nav-link active text-white"
              aria-current="page"
              href="home"
            >
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                Home
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" href="crear">
              <Link
                to="/addtask"
                style={{ textDecoration: "none", color: "white" }}
              >
                Alta Tarea
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" href="crear">
              <Link
                to="/addclient"
                style={{ textDecoration: "none", color: "white" }}
              >
                Alta Cliente
              </Link>
            </div>
          </li>
          <li className="nav-item">
            <div className="nav-link" href="crear">
              <Link
                to="/adduser"
                style={{ textDecoration: "none", color: "white" }}
              >
                Alta Usuario
              </Link>
            </div>
          </li>
        </ul>
      </div>
      <div className="p-3 text-white"><h5>App de Tareas</h5></div>
    </div>
  );
}
