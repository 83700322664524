import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Navbar from "./NavBar";

const Home = () => {
  const [tasks, setTasks] = useState([]);

  const getTasks = async () => {
    try {
      const res = await axios.get("/tasks");
      setTasks(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTasks();
  }, []);

  //if (tasks.length > 0) { console.log(tasks[0].user.user_name)}
 

  // const deleteClient = async (id) => {
  //   await axios.delete(`/clients/${id}`);
  //   getClients();
  // };

  const aux = tasks.map((t) => t)

  console.log("aux:", aux)

  return (
    <div>
      <Navbar />
      
      <table className="table">
        <thead className="table-secondary">
          <tr>
            <th>Tarea</th>
            <th>Cliente</th>
            <th>Usuario</th>
            <th>Estado</th>
            <th>Tecnico</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => ( 
            
            <tr key={task.id}>
              

              <td>
                <Link to={`/${task.id}`} style={{ textDecoration: "none" }}>
                  {task.task_name}
                </Link>
              </td>
              <td> {task.client_name} </td>
              <td> {task.user_name} </td>
              <td> {task.task_status} </td>
              <td> {task.task_tecnico}  </td>
              <td> {(task.createdAt).slice(0, 10).split("-").reverse().join("/")} </td>
              <td>  </td>
              <td>
                <Link
                  to={`/edit/${task.id}`}
                  style={{ textDecoration: "none" }}
                  className="button is-small is-danger"
                >
                  Edit{" "}
                </Link>
                {/* <button
                  onClick={console.log("clicked")}
                  type="button"
                  className="btn btn-danger"
                >
                  Delete
                </button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Home;
