import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Navbar from "./NavBar";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

export default function AddTask() {
  const [user, setUser] = useState([]);
  const [client, setClient] = useState([]);
  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      const res = await axios.get("/users");
      setUser(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getClients = async () => {
    try {
      const res = await axios.get("/clients");
      setClient(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    getUsers();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      Cliente: "",
      Usuario: "",
      Tarea: "",
      Descripcion: "",
      Estado: "",
      Tecnico: "",
    },
  });
  const onSubmit = (data) => {
    console.log(data);
    saveTasks(data);
    reset({
      Cliente: "",
      Usuario: "",
      Tarea: "",
      Descripcion: "",
      Estado: "",
      Tecnico: "",
    });
    alert("Tarea Creada con Exito")
    navigate("/");
  };
  console.log(errors);

  const saveTasks = async (e) => {
    //e.preventDefault();
    // console.log("task", e);
    try{
    await axios.post("/tasks", {
      task_name: e.Tarea,
      task_description: e.Descripcion,
      userId: e.Usuario,
      clientId: e.Cliente,
      task_status: e.Estado,
      task_tecnico: e.Tecnico,
    })}catch(err){
      console.log("task", err);

    };
  };

  return (
    <div>
      <Navbar />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            Cliente
          </label>
          <select
            style={{ width: "500px" }}
            className="form-control"
            type="text"
            name="cliente"
            placeholder="Cliente"
            {...register("Cliente", { required: true, maxLength: 50 })}
          >
            {client?.map((u) => (
              <option key={u.id} value={u.id}>
                {u.client_name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            Usuario
          </label>
          <select
            style={{ width: "500px" }}
            className="form-control"
            type="text"
            name="usuario"
            placeholder="Usuario"
            {...register("Usuario", { required: true, maxLength: 50 })}
          >
            {user?.map((u) => (
              <option key={u.id} value={u.id}>
                {u.user_name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            Tarea
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            type="text"
            placeholder="Tarea"
            {...register("Tarea", { required: true, maxLength: 100 })}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            Descripcion
          </label>
          <textarea
            style={{ width: "500px" }}
            className="form-control"
            {...register("Descripcion", {})}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            Estado
          </label>
          <select
            style={{ width: "500px" }}
            className="form-control"
            {...register("Estado", { required: true })}
          >
            <option value="Pendiente">Pendiente</option>
            <option value="Suspendido"> Suspendido</option>
            <option value="Finalizado"> Finalizado</option>
          </select>
        </div>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            Tecnico
          </label>
          <select
            style={{ width: "500px" }}
            className="form-control"
            {...register("Tecnico", {})}
          >
            <option value="Fabian">Fabian</option>
            <option value="Jose">Jose</option>
          </select>
          {/* <input
            style={{ width: "500px" }}
            className="form-control"
            type="text"
            placeholder="Tecnico"
            {...register("Tecnico", {})}
          /> */}
        </div>
        <input type="submit" />
      </form>
    </div>
  );
}
