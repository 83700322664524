import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "./NavBar";

export default function TaskDetail () {
    
  const { id } = useParams();
  const [task, setTask] = useState([]);
  const [fecha, setFecha] = useState("");
    console.log(id)
  const getTaskById = async () => {
    console.log("id", id);
    const response = await axios.get(`/tasks/${id}`);
    setTask(response.data[0]);
    setFecha(response.data[0].createdAt);
  };

  useEffect(() => {
    getTaskById(task);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
console.log("tareas: ", task)
let splitDate = fecha.slice(0, 10).split("-").reverse().join("/");

console.log(splitDate)
  return (
    <div>
      <Navbar />
      <h3>Detalla Tarea</h3>
      <div className="mb-3">
        <h5>
          Tarea: {task.task_name}
        </h5>
      </div>
      <div className="mb-3">
        <h5>Detalle: {task.task_description}</h5>
      </div>
      <div className="mb-3">
        <h5>Cliente: {task.client_name}</h5>
      </div>
      <div className="mb-3">
        <h5>Usuario: {task.user_name}</h5>
      </div>
      <div className="mb-3">
        <h5>Estado: {task.task_status}</h5>
      </div>
      <div className="mb-3">
        <h5>Tecnico: {task.task_tecnico} </h5>
      </div>
      <div className="mb-3">
        <h5>Fecha de inicio: {splitDate} </h5>
      </div>
    </div>
  );
};


