import Navbar from "./NavBar";
import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

export default function AddUser() {
  const [user, setUser] = useState([]);
  const navigate = useNavigate();

  const getUsers = async () => {
    try {
      const res = await axios.get("/users");
      setUser(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);
  //console.log("users:", user);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },
  });
  const onSubmit = (data) => {
    //console.log(data);
    saveUsers(data);
    reset({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
    alert("Usuario Creado con Exito")
    getUsers();
    //navigate("/");
    
  };

  console.log(errors);

  const saveUsers = async (e) => {
    //e.preventDefault();
    //console.log("user", e);
    await axios.post("/users", {
      user_name: e.firstName,
      user_lastName: e.lastName,
      user_email: e.email,
      user_phone: e.phone,
    });
  };

  return (
    <div>
      <Navbar />
      <h3>Alta Usuario</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label htmlFor="text" className="form-label">
            First Name
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_name"
            mane="user_name"
            type="text"
            placeholder="First name"
            // value={user.user_name}
            {...register("firstName", { required: true, maxLength: 50 })}
            // onChange={(e) => {
            //   handleChange(e);
            // }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="user_lastName" className="form-label">
            Last Name
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_lastName"
            name="user_lastName"
            type="text"
            placeholder="Last name"
            // value={user.user_lastName}
            {...register("lastName", { required: true, maxLength: 50 })}
            // onChange={(e) => {
            //     handleChange(e);
            //   }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="user_email" className="form-label">
            Email
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_email"
            name="user_email"
            type="email"
            placeholder="Email"
            // value={user.user_email}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            // onChange={(e) => {
            //     handleChange(e);
            //   }}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="user_phone" className="form-label">
            Phone Number
          </label>
          <input
            style={{ width: "500px" }}
            className="form-control"
            id="user_phone"
            name="user_phone"
            type="tel"
            placeholder="Phone number"
            // value={user.user_phone}
            {...register("phone", { required: true, maxLength: 12 })}
            // onChange={(e) => {
            //     handleChange(e);
            //   }}
          />
        </div>

        <input type="submit" className="btn btn-primary" />
      </form>
      <div style={{ padding: "20px 0 0 0" }}>
        <table className="table">
          <thead className="table-secondary">
            <tr>
              <th>Nombre</th>
              <th>Apellido</th>
              <th>Email</th>
              <th>Telefono</th>
            </tr>
          </thead>
          <tbody>
            {user.map((user) => (
              <tr key={user.id}>
                <td>
                  <Link to={`/${user.id}`} style={{ textDecoration: "none" }}>
                    {user.user_name}
                  </Link>
                </td>
                <td> {user.user_lastName} </td>
                <td> {user.user_email} </td>
                <td> {user.user_phone} </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
